export const RECIPE_TAB = "RECIPE_TAB";
export const SIGN_IN_TAB = "SIGN_IN_TAB";
export const SIGN_UP_TAB = "SIGN_UP_TAB";
export const USERS_TAB = "USERS_TAB";
export const ABOUT_TAB = "ABOUT_TAB";
export const PROFILE_TAB = "PROFILE_TAB";
export const PROFILE = "PROFILE";
export const DISPLAY_USER = "DISPLAY_USER";
export const CREATED_RECIPE_IDS = "CREATED_RECIPE_IDS";
export const LIKED_RECIPE_IDS = "LIKED_RECIPE_IDS";
export const FOLLOWING_IDS = "FOLLOWING_IDS";
export const FOLLOWING = "FOLLOWING";
export const FOLLOWERS = "FOLLOWERS";
export const CREATED_RECIPES = "CREATED_RECIPES";
export const LIKED_RECIPES = "LIKED_RECIPES";
export const FRIEND_RECIPES = "FRIEND_RECIPES";
export const ALL_RECIPES = "ALL_RECIPES";
export const CREATE_RECIPE = "CREATE_RECIPE";
export const SEARCH = "SEARCH";
export const PUSH = "PUSH";
export const POP = "POP";
